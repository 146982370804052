import React from 'react';
import { Video } from 'expo-av';
import { StyleSheet, Text, View, Dimensions } from 'react-native';

const {width, height} = Dimensions.get('window')

export default function VideoPlayer({player}) {
  return (
    <View style={styles.container}>
      <Video
      source={{ uri: player }}
      rate={1.0}
      volume={1.0}
      isMuted={true}
      resizeMode="cover"
      shouldPlay={true}
      isLooping={true}
      style={styles.video}
      />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#040D0D',
    alignItems: 'center',
    
  },
  lightText: {
    color: '#D9D9D9',
  },
  video: {
      width: width,
      height: height/2,
  }
});
