import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { ActivityIndicator, StyleSheet, Button, Text, View, Dimensions, ScrollView } from 'react-native';

import * as Permissions from 'expo-permissions';
import VideoPlayer from '../components/VideoPlayer/VideoPlayer';
import OcusVideo from '../public/PromoOcus.mp4'

export default function Home({navigation }) {
  
  return (
    <View style={styles.container}>
      <VideoPlayer player={OcusVideo}/>
      <View style={styles.menu}>
      
      <Button
        title="Services"
        onPress={() => navigation.navigate('Services')}
      />
      
      <Button
        title="Eva"
        onPress={() => navigation.navigate('Eva')}
      />
      </View>
      <Text style={styles.lightText} >Powered by Ocus</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  body: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  lightText: {
    color: '#D9D9D9',
    marginTop: '15px',
  },
  menu: {
  }
});
