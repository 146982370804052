import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, Dimensions } from "react-native";
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Home from "./pages/Home";
import Services from "./pages/Services";
import Photography from "./pages/Photography";
import Eva from "./pages/Eva";
import WebSites from "./pages/WebSites";
import Software from "./pages/Software";
import Design from "./pages/Design";
import Music from "./pages/Music";
import Artists from "./pages/Music/Artists";
import RecentTracks from "./pages/Music/RecentTracks";

const Stack = createStackNavigator();
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const App = () => {
  const [dimensions, setDimensions] = useState({ window, screen });

  const onChange = ({ window, screen }) => {
    setDimensions({ window, screen });
  };

  useEffect(() => {
    Dimensions.addEventListener("change", onChange);
    return () => {
      Dimensions.removeEventListener("change", onChange);
    };
  });

  return (
       <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen
          name="Home"
          component={Home}
          options={{ title: 'Ocus' }}
        />
        <Stack.Screen
          name="Services"
          component={Services}
          options={{ title: 'Join to the Future' }}
        />
        <Stack.Screen
          name="Photography"
          component={Photography}
          options={{ title: 'Is Photography' }}
        />
        <Stack.Screen
          name="Eva"
          component={Eva}
          options={{ title: 'Eva´s Tentations ' }}
        />
        <Stack.Screen
          name="WebSites"
          component={WebSites}
          options={{ title: 'Is WebSites' }}
        />
        <Stack.Screen
          name="Software"
          component={Software}
          options={{ title: 'Is Software' }}
        />
        <Stack.Screen
          name="Design"
          component={Design}
          options={{ title: 'Is Design' }}
        />
        <Stack.Screen
          name="Music"
          component={Music}
          options={{ title: 'Is Music' }}
        />
        <Stack.Screen
          name="Artists"
          component={Artists}
          options={{ title: 'Artists' }}
        />
        <Stack.Screen
          name="RecentTracks"
          component={RecentTracks}
          options={{ title: 'Recent Tracks' }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center", 
    backgroundColor: 'black'
    
  }
});

export default App;