import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { ActivityIndicator, Button, StyleSheet, Text, View, Dimensions, ScrollView } from 'react-native';

import * as Permissions from 'expo-permissions';


export default function Services({navigation, route }) {
  return (
    <View style={styles.container}>
      <Text style={styles.lightText}>{route.name}</Text>
      <View style={styles.menu}>
      <Button
        title="Design"
        onPress={() => navigation.navigate('Design')}
      />
      <Button
        title="Photography"
        onPress={() => navigation.navigate('Photography')}
      />
      <Button
        title="WebSites"
        onPress={() => navigation.navigate('WebSites')}
      />
      <Button
        title="Software"
        onPress={() => navigation.navigate('Software')}
      />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  body: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  lightText: {
    color: '#D9D9D9',
  },
  menu: {
    width: '300px',
  }
});
